body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Noto Color Emoji";
  src: local("Noto Color Emoji"),
    url("./fonts/NotoColorEmoji.ttf") format("truetype");
}


@media (min-resolution: 120dpi) {
  html {
    font-size: 80%;
  }

  img {
    transform: scale(0.8);
  }
}